import gql from 'graphql-tag'


export const FillOffer = gql`
    mutation fillOffer($id: ID!, $by: OfferFilledBy!) {
        fillOffer(id: $id, by: $by) {
            archived_at
        }
    }
`
