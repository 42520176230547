import gql from 'graphql-tag'


export const ApplyToOffer = gql`
    mutation applyToOffer(
        $offer: ID!,
        $message: String!,
        $files: UpdateFileRelation,
    ) {
        applyToOffer(offer: $offer, message: $message, files: $files) {
            candidacies (first: 500) {
                data {
                    offer {
                        id
                        label
                        reference
                        full_slug
                    }
                }
            }
        }
    }
`
