import gql from 'graphql-tag'

import { PaginationFragment } from '../Fragments/PaginationFragment'
import { CandidacyFragment } from '../Fragments/CandidacyFragment'

import { PER_PAGE } from './constants'


export const RejectedCandidacies = gql`
    query RejectedCandidacies($reference: String!, $page: Int) {
        candidacies:offerByReference(reference: $reference) {
            rejectedCandidacies(first:${PER_PAGE}, page:$page){
                data{
                    ...candidacy
                }
                paginatorInfo{
                    ...pagination
                }
            }
        }
    }

    ${CandidacyFragment.fragment}
    ${PaginationFragment.fragment}
`
