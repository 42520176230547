import gql from 'graphql-tag'

import { CandidacyFragment } from '../Fragments/CandidacyFragment'


export const GetCandidacy = gql`
    query Candidacies($offer: ID!, $candidacy: ID!) {
        candidacy:candidacyForOffer(offer: $offer, candidacy: $candidacy) {
            ...candidacy
        }
    }

    ${CandidacyFragment.fragment}
`
