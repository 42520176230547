import gql from 'graphql-tag'


export const UpdateOffer = gql`
    mutation updateOffer(
        $id: ID!,
        $input: UpdateOfferInput!
    ) {
        updateOffer(id: $id, input: $input) {
            id
            reference
        }
    }
`
