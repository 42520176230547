import gql from 'graphql-tag'


export const RestoreOffer = gql`
    mutation unarchiveOffer($id: ID!) {
        unarchiveOffer(id: $id){
            archived_at
        }
    }
`
