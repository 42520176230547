import gql from 'graphql-tag'

import { PaginationFragment } from '../Fragments/PaginationFragment'
import { CandidacyFragment } from '../Fragments/CandidacyFragment'

import { PER_PAGE } from './constants'
import ProfilesCountSubQueries from './ProfilesCountSubQueries'


export const OfferForDashboard = gql`
    query OfferForDashboard($reference: String!, $offer: ID) {
        offer:ownedOfferByReference(reference: $reference) {
            id
            label
            slug
            reference
            description
            candidacies_count
            contract
            duration
            distance
            pertinence
            duration_type
            degree_level
            experience
            published_at
            available_at
            archived_at
            location {
                city
            }
            skills {
                id
                name
            }

            candidacies(first:${PER_PAGE}){
                data{
                    ...candidacy
                }
                paginatorInfo{
                    ...pagination
                }
            }

            contactedCandidacies(first:${PER_PAGE}){
                data{
                    ...candidacy
                }
                paginatorInfo{
                    ...pagination
                }
            }

            rejectedCandidacies(first:${PER_PAGE}){
                data{
                    ...candidacy
                }
                paginatorInfo{
                    ...pagination
                }
            }

        }
        ${ProfilesCountSubQueries}
        profiles:matchingProfiles(
            percentage: 100,
            offer: $offer,
            max: 100,
            first: ${PER_PAGE},
            page: 1
        ){
            data{
                id
                first_name
                last_name
                title
                degree_level
                experience
                qualities
                matching_percentage
                skills {
                    id
                    name
                    category{
                        id
                        name
                    }
                }
            }
            paginatorInfo {
                ...pagination
            }
        }
    }

    ${CandidacyFragment.fragment}
    ${PaginationFragment.fragment}
`
