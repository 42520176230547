import gql from 'graphql-tag'

import { OfferCardFragment } from '../Fragments/OfferCardFragment'
import { ShareFragment } from '../Fragments/ShareFragment'


export const OfferByReference = gql`
    query OfferByReference($slug: String!) {
        offerByReference(reference: $slug) {
            id
            label
            slug
            reference
            description
            candidacies_count
            contract
            duration
            distance
            pertinence
            duration_type
            degree_level
            experience
            is_highlighted
            published_at
            available_at
            sector {
                id
                slug
            }
            similar_offers(first: 4) {
                data {
                    ...offerCard
                }
            }
            location {
                id
                address
                address_2
                zip_code
                city
                image
                is_main
                lat
                long
            }
            provider {
                __typename
                ... on Company {
                    name
                    logo
                    full_slug
                    links {
                        id
                        type
                        value
                    }
                }
            }
            skills {
                id
                name
            }
            share {
                ...share
            }
        }
    }

    ${OfferCardFragment.fragment}
    ${ShareFragment.fragment}
`
