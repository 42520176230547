import gql from 'graphql-tag'

import { ProfileFragment } from './ProfileFragment'


export const CandidacyFragment = {
  fragment: gql`
      fragment candidacy on Candidacy {
          id
          status
          applied_at
          matching_percentage
          user {
              ...profile
          }
      }
      ${ProfileFragment.fragment}
  `,
}
