import gql from 'graphql-tag'


export const NotifyProfile = gql`
  mutation notifyProfile($id: ID!, $profiles: [ID!]) {
    notifyProfile(id: $id, profiles: $profiles) {
      provider {
        ... on Company {
          notification_credits
        }
      }
    }
  }
`
