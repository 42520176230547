import gql from 'graphql-tag'

import { LimitedProfileFragment } from './LimitedProfileFragment'


export const PendingCandidacyFragment = {
  fragment: gql`
      fragment pendingCandidacy on PendingCandidacy {
          id
          status
          applied_at
          user {
              ...limitedProfile
          }
      }
      ${LimitedProfileFragment.fragment}
  `,
}
