import gql from 'graphql-tag'


export const ArchiveOffer = gql`
    mutation archiveOffer($id: ID!) {
        archiveOffer(id: $id) {
            archived_at
        }
    }
`
