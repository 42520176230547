import gql from 'graphql-tag'


export const RejectProfile = gql`
  mutation rejectProfile($id: ID!, $profiles: [ID!]) {
    rejectProfile(id: $id, profiles: $profiles) {
      provider {
        ... on Company {
          notification_credits
        }
      }
    }
  }
`
