import gql from 'graphql-tag'

import { CandidacyFragment } from '../Fragments/CandidacyFragment'
import { PaginationFragment } from '../Fragments/PaginationFragment'

import { PER_PAGE } from './constants'


export const MarkCandidaciesAsRejected = gql`
    mutation markCandidaciesAsRejected($offer: ID!, $candidacies: [ID!]) {
        candidacies:markCandidaciesAsRejected(offer: $offer, candidacies: $candidacies) {
            candidacies(first: ${PER_PAGE}) {
                data {
                    ...candidacy
                }
                paginatorInfo{
                    ...pagination
                }
            }
            contactedCandidacies(first: ${PER_PAGE}) {
                data {
                    ...candidacy
                }
                paginatorInfo{
                    ...pagination
                }
            }
            rejectedCandidacies(first: ${PER_PAGE}) {
                data {
                    ...candidacy
                }
                paginatorInfo{
                    ...pagination
                }
            }
        }
    }

    ${CandidacyFragment.fragment}
    ${PaginationFragment.fragment}
`
