import gql from 'graphql-tag'

import { PaginationFragment } from '../Fragments/PaginationFragment'

import { PER_PAGE } from './constants'
import ProfilesCountSubQueries from './ProfilesCountSubQueries'


export const MatchingProfiles = gql`
    query matchingProfiles(
        $percentage: Float!,
        $offer: ID,
        $orderBy: [OrderByClause!],
        $page: Int,
        $max: Float
    ) {
        ${ProfilesCountSubQueries}
        profiles:matchingProfiles(
            percentage: $percentage,
            offer: $offer,
            max: $max,
            orderBy: $orderBy,
            first: ${PER_PAGE},
            page: $page
        ){
            data{
                id
                first_name
                last_name
                title
                degree_level
                experience
                qualities
                matching_percentage
                skills {
                    id
                    name
                    category{
                        id
                        name
                    }
                }
            }
            paginatorInfo {
                ...pagination
            }
        }
    }
    ${PaginationFragment.fragment}
`
