import gql from 'graphql-tag'


export const CreateOffer = gql`
    mutation createOffer(
        $input: CreateOfferInput!
    ) {
        createOffer(input: $input) {
            id
            reference
        }
    }
`
