import gql from 'graphql-tag'


export const ProfileFragment = {
  fragment: gql`
      fragment profile on Profile {
          id
          first_name
          last_name
          title
          degree_level
          experience
          qualities
          skills {
              id
              name
              category{
                  id
                  name
              }
          }
          email
          phone
          city
          age
          family_situation
          avatar
          description
          certifications{
              id
              title
              establishment
              year
          }
          degrees {
              id
              title
              school
              year
          }
          jobs{
              id
              title
              company
              description
              contract
              start_at
              end_at
          }
          links {
              id
              type
              value
          }
      }
  `,
}
