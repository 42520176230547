import { query } from '../../../helpers/GraphqlHelpers'

import { OfferByReference } from './OfferByReference'
import { AccountOffers } from './AccountOffers'
import { ApplyToOffer } from './ApplyToOffer'
import { MatchingPercentages } from './MatchingPercentages'
import { MatchingProfiles } from './MatchingProfiles'
import { MatchingProfilesCount } from './MatchingProfilesCount'
import { OfferForDashboard } from './OfferForDashboard'
import { RejectedProfiles } from './RejectedProfiles'
import { NotifiedProfiles } from './NotifiedProfiles'
import { Candidacies } from './Candidacies'
import { ContactedCandidacies } from './ContactedCandidacies'
import { RejectedCandidacies } from './RejectedCandidacies'
import { RejectProfile } from './RejectProfile'
import { NotifyProfile } from './NotifyProfile'
import { CreateOffer } from './CreateOffer'
import { UpdateOffer } from './UpdateOffer'
import { MarkCandidaciesAsRejected } from './MarkCandidaciesAsRejected'
import { MarkCandidaciesAsContacted } from './MarkCandidaciesAsContacted'
import { GetCandidacy } from './GetCandidacy'
import { ArchiveOffer } from './ArchiveOffer'
import { FillOffer } from './FillOffer'
import { RestoreOffer } from './RestoreOffer'


export default {
  byReference: (variables, headers = {}) => query(OfferByReference, variables, headers),
  accountOffers: (variables, headers = {}) => (query(AccountOffers, variables, headers)),
  applyToOffer: (variables, headers = {}) => (query(ApplyToOffer, variables, headers)),
  // Offer Dashboard
  offerForDashboard: (variables, headers = {}) => (query(OfferForDashboard, variables, headers)),
  // Pending candidacies
  matchingPercentages: (variables, headers = {}) => (query(MatchingPercentages, variables, headers)),
  matchingProfiles: (variables, headers = {}) => (query(MatchingProfiles, variables, headers)),
  matchingProfilesCount: (variables, headers = {}) => (query(MatchingProfilesCount, variables, headers)),
  rejectedProfiles: (variables, headers = {}) => (query(RejectedProfiles, variables, headers)),
  notifiedProfiles: (variables, headers = {}) => (query(NotifiedProfiles, variables, headers)),
  rejectProfile: (variables, headers = {}) => (query(RejectProfile, variables, headers)),
  notifyProfile: (variables, headers = {}) => (query(NotifyProfile, variables, headers)),
  // Candidacies
  candidacy: (variables, headers = {}) => (query(GetCandidacy, variables, headers)),
  candidacies: (variables, headers = {}) => (query(Candidacies, variables, headers)),
  contactedCandidacies: (variables, headers = {}) => (query(ContactedCandidacies, variables, headers)),
  rejectedCandidacies: (variables, headers = {}) => (query(RejectedCandidacies, variables, headers)),
  rejectCandidacies: (variables, headers = {}) => (query(MarkCandidaciesAsRejected, variables, headers)),
  contactCandidacies: (variables, headers = {}) => (query(MarkCandidaciesAsContacted, variables, headers)),
  //
  createOffer: (variables, headers = {}) => (query(CreateOffer, variables, headers)),
  updateOffer: (variables, headers = {}) => (query(UpdateOffer, variables, headers)),
  //
  archiveOffer: (variables, headers = {}) => (query(ArchiveOffer, variables, headers)),
  fillOffer: (variables, headers = {}) => (query(FillOffer, variables, headers)),
  restoreOffer: (variables, headers = {}) => (query(RestoreOffer, variables, headers)),
}
