import gql from 'graphql-tag'

import { query } from '../../helpers/GraphqlHelpers'


const offerFragment = gql`
    fragment offerFragment on Offer {
        id
        reference
        full_slug
        label
        type
        files {
            id
            preview_url
            pdf_url
            title
        }
        is_active
        maximal_execution_at
        city
        budget
        introduction
        description
        published_at
        link
        keywords
        company_name
        user {
            first_name
            last_name
            id
        }
        categories {
            id
            name
            color
            slug
        }
        similar_offers(first: 3) {
            data {
                reference
                label
                type
                maximal_execution_at
                city
                budget
                introduction
                description
                published_at
                keywords
                user {
                    first_name
                    last_name
                }
                categories {
                    id
                    name
                    color
                    slug
                }
            }
        }
    }
`

export const getOffer = (variables, headers) => query(gql`
    query offerByFullSlug($slug: String!) {
        offer:offerByFullSlug(slug: $slug) {
            ...offerFragment
        }
    }
    ${offerFragment}
  `,
variables, headers)
