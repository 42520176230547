import gql from 'graphql-tag'


export const MatchingProfilesCount = gql`
    query matchingProfilesCount($skills: [ID!]!, $offer: ID){
        q_70: matchingProfilesCount(skills:$skills, percentage: 70, offer: $offer, max: 79.99)
        q_80: matchingProfilesCount(skills:$skills, percentage: 80, offer: $offer, max: 89.99)
        q_90: matchingProfilesCount(skills:$skills, percentage: 90, offer: $offer, max: 99.99)
        q_100: matchingProfilesCount(skills:$skills, percentage: 100, offer: $offer, max: 100)
    }
`
