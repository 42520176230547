/* eslint-disable max-lines */
import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import app from '../../config/app'
import OfferModel from '../../data/models/Offer'
import Share from '../../data/models/Share'
import offerQueries from '../../graphql/query/Offer'
import { offerJsonLd } from '../../helpers/JsonLdHelpers'
import { assetUrl, routeParamSlug } from '../../helpers/UrlHelpers'
import { pushToDataLayer } from '../../helpers/GTMHelpers'
import i18n from '../../i18n'
import ApiSagas from '../../redux/api/sagas'
import { actions as AppActions, pageType, resourceType, selectors as AppSelectors } from '../../redux/app/redux'
import { actions as AuthActions, selectors as AuthSelectors } from '../../redux/auth/redux'
import routes, { Router } from '../../routes'
import { selectors } from '../UserPublishOffer/redux'

import { getOffer } from './queries'
import { actions as OfferActions, actions as OfferDetailsActions } from './redux'


const getFiles = (source, values) => {
  const sourceByKey = (source && source.files
    && source.files.reduce(
      (r, { id, ...value }) => ({
        ...r,
        [id]: { id, ...value },
      }),
      {}
    ))
    || {}
  const update = []
  const create = []

  const compare = (value) => {
    const src = sourceByKey[value.id]

    if (value.id && src) {
      if (value.title !== src.title) {
        update.push({
          id: src.id,
          title: value.title,
        })
      }
    } else if (value.key && !src) {
      create.push({
        path: value.key,
        title: value.title,
      })
    }
  }
  const valuesIds = values.files.map((entry) => entry.id)
  const _delete = !source ? [] : source.files.filter((entry) => !valuesIds.includes(entry.id))
    .map((entry) => entry.id)

  values.files.forEach(compare)

  return {
    create: create || null,
    update: update || null,
    delete: _delete || null,
  }
}

export default class OfferDetailsSagas {

  static* init({ asPath, query, res }) {
    return yield call(OfferDetailsSagas.loadOffer, query, asPath, res)
  }

  static* loadOffer({ slug = '/' }, asPath, res) {
    yield put(showLoading())
    const errors = []

    // Company
    const result = yield call(ApiSagas.query, getOffer, { slug })
    // Check if errors

    if (result.errors && result.errors.length) {
      result.errors.map((error) => errors.push(error))
    }

    // Check errors
    if (errors && errors.length >= 1) {
      console.log('[OFFER] 404 error loading data', errors)
      yield put(hideLoading())
      return { error: 404 }
    }

    const returnedData = yield call(OfferDetailsSagas.transformData, result.data)

    if (returnedData === null) {
      console.log('[OFFER] 404 error loading data', result)
      yield put(hideLoading())
      return { error: 404 }
    }

    const breadCrumb = yield call(OfferDetailsSagas.transformBreadcrumb, returnedData)
    const jsonld = yield call(OfferDetailsSagas.transformJsonld, returnedData)
    const seo = yield call(OfferDetailsSagas.transformSeo, returnedData)

    yield all([
      put(OfferDetailsActions.applyToOfferReset()),
      put(OfferDetailsActions.setData(returnedData)),
      put(AppActions.setBreadcrumb(breadCrumb)),
      put(AppActions.setResource(resourceType.OFFER, returnedData?.offer?.id)),
      put(AppActions.setJsonld(jsonld)),
      put(AppActions.setSeo(seo)),
    ])

    yield all([
      put(OfferDetailsActions.setLoaded(true)),
      put(hideLoading()),
    ])
  }

  static* applyToOffer({ payload }) {
    const me = yield select(AuthSelectors.user)
    const data = yield select(selectors.data)

    const values = payload.values

    const input = {
      offer: values.offer,
      message: values.message,
      files: getFiles(data, values),
    }

    const result = yield call(ApiSagas.query, offerQueries.applyToOffer, { ...input })

    if (result.errors && result.errors.length) {
      yield put(OfferActions.applyToOfferError(result.errors))
    } else {
      yield put(AuthActions.setUser({ ...me, ...result.data.applyToOffer }))

      const offer = result.data.applyToOffer.candidacies.data
        .find((candidacy) => candidacy.offer.id === payload.values.offer)?.offer

      if (offer) {
        Router.replaceRoute(routes.userCandidacy.name, { id: offer.reference })
      }

      pushToDataLayer({
        event: 'declenche-evenement',
        eventCategory: 'formulaire',
        eventAction: 'opportunités',
        eventLabel: offer?.label?.toLocaleLowerCase(),
      })

      yield put(OfferActions.applyToOfferSuccess(result.data.applyToOffer))
    }
  }

  static transformData(data) {
    if (!data) {
      return null
    }

    const offer = OfferModel(data?.offer)

    return {
      ...data,
      offer,
    }
  }

  static* transformBreadcrumb(data) {
    if (data) {
      const pages = yield select(AppSelectors.pages)

      return [
        {
          label: pages[pageType.HOME].title,
          title: pages[pageType.HOME].title,
          link: pages[pageType.HOME].link,
          route: routes.page.name,
          routeParams: { slug: routeParamSlug(pages[pageType.HOME].link) },
        },
        {
          label: pages[pageType.OFFERS].title,
          title: pages[pageType.OFFERS].title,
          link: pages[pageType.OFFERS].link,
          route: routes.page.name,
          routeParams: { slug: routeParamSlug(pages[pageType.OFFERS].link) },
        },
        {
          label: data?.offer?.title,
          title: data?.offer?.title,
        },
      ]
    }

    return []
  }

  static transformJsonld(data) {
    if (data) {
      return offerJsonLd(data)
    }

    return {}
  }

  static transformSeo(data) {
    if (data && data.share) {
      const transformedShare = Share(data.share)

      const title = i18n.t('SEO_offer_title', {
        title: data.label,
        contract: i18n.t(data.contract.toLowerCase()),
        city: data?.location?.city,
      })

      const description = i18n.t('SEO_offer_description', {
        title: data.label,
        contract: i18n.t(data.contract.toLowerCase()),
        company: data?.company?.title,
        experience: i18n.t(`experience_type_${data.experience.toLowerCase()}`),
      })

      return {
        ...transformedShare,
        title,
        description,
        url: `${app.APP_URL}${Router.getRouteUrl(routes.offer, { slug: data.full_slug })}`,
        image: transformedShare.image ? assetUrl(transformedShare.image, { width: 1024 }) : '',
        noindex: false,
      }
    }

    return {}
  }

  static* loop() {
    yield all([
      takeLatest(OfferActions.applyToOffer.getType(), OfferDetailsSagas.applyToOffer),
    ])
  }

}
