import gql from 'graphql-tag'


export const MatchingPercentages = gql`
    mutation matchingPercentages(
        $offers: [ID!]
    ) {
        matchingPercentages(offers: $offers) {
            offer
            percentage
        }
    }
`
