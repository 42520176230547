import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'

import { requestInitialState, requestReducers } from '../../helpers/ReduxHelpers'
import Errors from '../../data/models/Errors'


export const key = 'UserPublishOffer'


//
// Initial state
//
const initialState = {
  data: null,
  loaded: false,
  submitOffer: requestInitialState(),
}

//
// Actions
//
export const actions = {
  setData: createAction('userPublishOffer: setData', (data) => ({ data })),
  setLoaded: createAction('userPublishOffer: setLoaded', (loaded) => ({ loaded })),
  //
  submitOffer: createAction('userPublishOffer: submitOffer', (values) => ({ values })),
  submitOfferError: createAction('userPublishOffer: submitOfferError', (errors) => Errors(errors)),
  submitOfferSuccess: createAction('userPublishOffer: submitOfferSuccess', (data) => ({ data })),
}

//
// Reducer
//
export const reducer = createReducer({
  [actions.setData]: (state, { data }) => Immutable.static.merge(state, { data }),
  [actions.setLoaded]: (state, { loaded }) => Immutable.static.merge(state, { loaded }),
  ...requestReducers(actions, 'submitOffer'),
}, initialState)

//
// Selectors
//

const root = (state) => state[key]
const isLoaded = (state) => root(state).loaded
const data = (state) => root(state).data
const submitOffer = (state) => root(state).submitOffer

export const selectors = {
  isLoaded,
  data,
  submitOffer,
}
