export default [
  { id: 'matching_70', value: 'matchingProfilesCount(percentage: 70, offer: $offer, max: 79.99)' },
  { id: 'matching_80', value: 'matchingProfilesCount(percentage: 80, offer: $offer, max: 89.99)' },
  { id: 'matching_90', value: 'matchingProfilesCount(percentage: 90, offer: $offer, max: 99.99)' },
  { id: 'matching_100', value: 'matchingProfilesCount(percentage: 100, offer: $offer, max: 100)' },
  { id: 'notified_70', value: 'notifiedProfilesCount(percentage: 70, offer: $offer, max: 79.99)' },
  { id: 'notified_80', value: 'notifiedProfilesCount(percentage: 80, offer: $offer, max: 89.99)' },
  { id: 'notified_90', value: 'notifiedProfilesCount(percentage: 90, offer: $offer, max: 99.99)' },
  { id: 'notified_100', value: 'notifiedProfilesCount(percentage: 100, offer: $offer, max: 100)' },
  { id: 'rejected_70', value: 'rejectedProfilesCount(percentage: 70, offer: $offer, max: 79.99)' },
  { id: 'rejected_80', value: 'rejectedProfilesCount(percentage: 80, offer: $offer, max: 89.99)' },
  { id: 'rejected_90', value: 'rejectedProfilesCount(percentage: 90, offer: $offer, max: 99.99)' },
  { id: 'rejected_100', value: 'rejectedProfilesCount(percentage: 100, offer: $offer, max: 100)' },
].map((v) => `${v.id}:${v.value}\n`)

