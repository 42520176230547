import gql from 'graphql-tag'

import { OfferCardFragment } from '../Fragments/OfferCardFragment'


export const AccountOffers = gql`
    mutation accountOffers(
        $id: ID!,
        $savedOffers: UpdateOfferRelation!
    ) {
        accountOffers(id: $id, savedOffers: $savedOffers) {
            savedOffers(first: 500) {
                data {
                    ...offerCard
                }
            }
        }
    }
    
    ${OfferCardFragment.fragment}
`
