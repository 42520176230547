import gql from 'graphql-tag'


export const LimitedProfileFragment = {
  fragment: gql`
      fragment limitedProfile on LimitedProfile {
          id
          first_name
          last_name
          title
          degree_level
          experience
          qualities
          skills {
              id
              name
              category{
                  id
                  name
              }
          }
      }
  `,
}
