import gql from 'graphql-tag'

import { PendingCandidacyFragment } from '../Fragments/PendingCandidacyFragment'
import { PaginationFragment } from '../Fragments/PaginationFragment'

import { PER_PAGE } from './constants'
import ProfilesCountSubQueries from './ProfilesCountSubQueries'


export const RejectedProfiles = gql`
    query RejectedProfiles(
        $percentage: Float!,
        $offer: ID,
        $orderBy: [OrderByClause!],
        $page: Int,
        $max: Float
    ) {
        ${ProfilesCountSubQueries}
        profiles:rejectedProfiles(
            percentage: $percentage,
            offer: $offer,
            max: $max,
            orderBy: $orderBy,
            first: ${PER_PAGE},
            page: $page
        ){
            data{
                ...pendingCandidacy
            }
            paginatorInfo {
                ...pagination
            }
        }
    }

    ${PendingCandidacyFragment.fragment}
    ${PaginationFragment.fragment}
`
